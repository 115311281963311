<template>
  <v-app-bar
    id="app-bar"
    app
    absolute
    dark
    elevation="0"
    color="transparent"
    :class="{'px-12': $vuetify.breakpoint.mdAndUp}"
  >
    <v-toolbar-title class="ml-0">
      <!-- <span v-if="$vuetify.breakpoint.mdAndUp">Sistema </span> <span>Gavcom</span> -->
      <v-img
        position="left"
        height="60"
        :src="require('@/assets/logo_gavcom_100_60.png')"
      ></v-img>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    
    <v-btn v-if="!userData.passwordExpired" class="ml-2" min-width="0" text :to="{ name: 'Precios'}">
      <v-icon>mdi-currency-usd</v-icon> <span v-if="$vuetify.breakpoint.smAndUp">Precios</span>
    </v-btn>

    <template v-if="activeSession">
      <v-btn v-if="!userData.passwordExpired" class="ml-2" min-width="0" text exact :to="{ name: 'Home'}">
          <v-icon>mdi-home-analytics</v-icon> <span v-if="$vuetify.breakpoint.mdAndUp">Inicio</span>
      </v-btn>
      <v-btn  class="ml-2" min-width="0" icon >
        <base-profile-menu item/>
      </v-btn>
    </template>
    <template v-else>
      <v-btn class="ml-2" min-width="0" text :to="{ name: 'Registro'}">
        <v-icon>mdi-account-plus</v-icon> <span v-if="$vuetify.breakpoint.smAndUp">Registro</span>
      </v-btn>
      <v-btn  class="ml-2" min-width="0" text :to="{ name: 'Login'}">
        <v-icon>mdi-login</v-icon> <span v-if="$vuetify.breakpoint.smAndUp">Acceder al Sistema</span>
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState(["userData"]),
    ...mapGetters(['activeSession'])
  },
}
</script>